import { Link } from "@remix-run/react";

import { cn } from "~/lib/utils";

type LogoProps = {
  className?: string;
  size?: "sm" | "md" | "lg";
  href?: string;
};

export function Logo({ className, size = "md", href = "/home" }: LogoProps) {
  const sizes = {
    sm: "h-6",
    md: "h-8",
    lg: "h-10",
  };

  return (
    <Link to={href} className={cn("flex items-center", className)}>
      <img
        src="/images/logo/logo.svg"
        alt="TalentPassport"
        className={cn(sizes[size])}
      />
    </Link>
  );
}
